import Img from "gatsby-image";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { withPrefix, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const BackgroundImage = require("gatsby-background-image").default;

interface BoxLayoutProps {
	readonly title: JSX.Element | string;
	readonly text: JSX.Element | string;
	readonly textColor?: string;
	readonly links?: ReadonlyArray<{ readonly label: string; readonly url: string }>;
	readonly linkStyle?: React.CSSProperties;
	readonly imageFluid: any;
	readonly imageSize?: "cover" | "contain";
	readonly imagePosition?: string; // e.g. top, bottom, etc.  See the `background-position` property
	readonly style?: React.CSSProperties;
	readonly backgroundStyle?: React.CSSProperties;
	readonly minHeights: [string, string, string];
}

export function BoxLayout(props: BoxLayoutProps): JSX.Element {
	const imageSize = props.imageSize || "cover";
	const imagePosition = props.imagePosition || "center";

	const BoxBackground = styled.div`
		min-height: ${props.minHeights[0]};
		text-align: center;
		@media (min-width: 742px) {
			min-height: ${props.minHeights[1]};
			display: flex;
			align-items: start;
			flex-direction: column;
		}
		@media (min-width: 920px) {
			min-height: ${props.minHeights[2]};
		}
		p {
			padding: 0;
			margin: 0;
		}
	`;
	// TODO: flex, vertical

	const StyledBackgroundImage = styled(BackgroundImage)`
		background-position: ${imagePosition};
		background-size: ${imageSize};
		height: 100%;
		padding-top: 100%;
		width: 100%;
		z-index: 0;
		flex: 1;
		@media (min-width: 742px) {
			padding: 0;
		}
		@media (min-width: 920px) {
		}
		&:before,
		&:after {
			background-size: ${imageSize};
			background-position: ${imagePosition};
		}
	`;

	/** Ensure padding around the content */
	const Padding = styled.div`
		padding: 3em 0 0 0;
		margin: 0 auto;
	`;

	const ContentSizingAndAnchoring = styled.div`
		@media (min-width: 742px) {
			/*width: 220px;*/
		}
		@media (min-width: 920px) {
			/*width: 300px;*/
			font-size: 110%;
		}
	`;

	return (
		<BoxBackground style={props.style}>
			<Padding
				style={{
					...(props.textColor ? { color: props.textColor } : {}),
				}}
			>
				<ContentSizingAndAnchoring>
					{typeof props.title === "string" ? (
						<h3 style={{ padding: "0 0 1em" }}>{props.title}</h3>
					) : (
						props.title
					)}
					{typeof props.text === "string" ? (
						<p style={{ lineHeight: "1.75em" }}>{props.text}</p>
					) : (
						props.text
					)}
					{props.links ? (
						<div style={{ marginTop: "1em", marginBottom: "1em" }}>
							{(props.links || []).map((x, i) => (
								<a key={"link" + (i + 1)} href={x.url} style={props.linkStyle}>
									{x.label}
								</a>
							))}
						</div>
					) : null}
				</ContentSizingAndAnchoring>
			</Padding>
			<StyledBackgroundImage fluid={props.imageFluid} />
		</BoxBackground>
	);
}

export interface BoxRowProps {
	readonly backgroundStyle?: React.CSSProperties;
	readonly children: any;
}

const BoxRowStyled = styled.div`
	max-width: 742px;
	width: 100%;
	padding-bottom: 2em;
	@media (min-width: 742px) {
		display: flex;
		justify-content: space-between;
		flexwrap: wrap;
		margin: 0 auto;
	}
`;

export function BoxRow(props: BoxRowProps): JSX.Element {
	return (
		<div
			style={{
				textAlign: "center",
				...props.backgroundStyle,
			}}
		>
			<BoxRowStyled>{props.children}</BoxRowStyled>
		</div>
	);
}
