import { graphql, StaticQuery, withPrefix } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import * as B from "react-bootstrap";
import styled, { css } from "styled-components";
import { BoxRow } from "../components/BoxLayout";
import {
	CommonArticleLayout,
	CommonArticleLayoutProps1,
	CommonLayout,
	CommonLayoutProps1,
} from "../components/CommonLayout";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./index.css";

const BackgroundImage = require("gatsby-background-image").default;

const IndexPage = () => (
	<StaticQuery
		query={graphql`
			query {
				bigLogo: file(relativePath: { eq: "galoxee-logo-2021x750.png" }) {
					childImageSharp {
						fluid(maxWidth: 2021) {
							...GatsbyImageSharpFluid
						}
					}
				}

				showcaseImage: file(relativePath: { eq: "galoxee-background-1920x713.png" }) {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}

				image1: file(relativePath: { eq: "Lightning.png" }) {
					# ...standardImage
					childImageSharp {
						fixed(width: 467, height: 352) {
							...GatsbyImageSharpFixed
						}
						fluid(quality: 100, maxWidth: 4670) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}

				abcMagnete: file(relativePath: { eq: "26-02-19-03933.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1782) {
							...GatsbyImageSharpFluid
						}
					}
				}
				mbFarm: file(relativePath: { eq: "090920_Product_Shots_farm.png" }) {
					childImageSharp {
						fluid(maxWidth: 1782) {
							...GatsbyImageSharpFluid
						}
					}
				}
				mbAfrika: file(relativePath: { eq: "090120_Product_Shots_africa.png" }) {
					childImageSharp {
						fluid(maxWidth: 1782) {
							...GatsbyImageSharpFluid
						}
					}
				}
				mbJungen: file(relativePath: { eq: "090120_Product_Shot_boys.png" }) {
					childImageSharp {
						fluid(maxWidth: 1782) {
							...GatsbyImageSharpFluid
						}
					}
				}
				mbMaedchen: file(relativePath: { eq: "091220_Product_Shots_girls.png" }) {
					childImageSharp {
						fluid(maxWidth: 1782) {
							...GatsbyImageSharpFluid
						}
					}
				}
				mbBaustelle: file(relativePath: { eq: "090420_Product_Shots_construction.png" }) {
					childImageSharp {
						fluid(maxWidth: 1782) {
							...GatsbyImageSharpFluid
						}
					}
				}
				spiel01: file(relativePath: { eq: "spiel01.png" }) {
					childImageSharp {
						fluid(maxWidth: 350) {
							...GatsbyImageSharpFluid
						}
					}
				}
				spiel02: file(relativePath: { eq: "spiel02.png" }) {
					childImageSharp {
						fluid(maxWidth: 350) {
							...GatsbyImageSharpFluid
						}
					}
				}
				spiel03: file(relativePath: { eq: "spiel03.png" }) {
					childImageSharp {
						fluid(maxWidth: 350) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`}
		render={(data) => <IndexPage1 data={data} />}
	/>
);

const FlexRow1 = styled.div`
	margin: 1rem auto 0;
	width: 100%;
	height: 352px;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	@media (min-width: 742px) {
		display: flex;
		text-align: left;
	}
`;

const ShowcaseHeader = styled.header`
	max-height: 450px;
	text-align: center;
	.bg-image {
		/* position: absolute; */
		/*display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden; */
		width: 100%;
		height: 450px;
		object-fit: cover;
		/* height: 450px; */
		/* z-index: -1; */
	}

	.bg-image2 {
		height: 450px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.gatsby-background-image-hero:after {
		background-position: center;
	}

	h1 {
		margin: 0;
		padding: 0;
	}
`;

const ShowcaseContentDiv = styled.div``;

const ShowcaseTaglineDiv = styled.div`
	padding: 1.2em;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 1em;
	text-align: center;
	color: white;
	margin-top: 1em;
`;

const IndexPage1 = ({ data }: { data: any }) => {
	const productsRef = React.useRef(null);
	const scrollToProducts = () => (productsRef?.current as any)?.scrollIntoView();

	return (
		<Layout>
			<SEO title="Home" keywords={[`galoxee`, `alphabet`, `magnets`, `kids`, `reading`]} />
			<ShowcaseHeader>
				<BackgroundImage
					Tag="section"
					className="bg-image2"
					classId="hero"
					fluid={data.showcaseImage.childImageSharp.fluid}
				>
					<ShowcaseContentDiv>
						{/* <div style={{ textAlign: "center" }}>
						<Img
							fluid={data.bigLogo.childImageSharp.fluid}
							style={{ maxWidth: "300px" }}
						/>
					</div> */}
						<ShowcaseTaglineDiv>
							<h1 style={{}}>Clevere Spiele für schlaue Kinder</h1>
							<div className="row">
								<div className="col-sm-6">
									<div
										className="card"
										style={{ backgroundColor: "transparent" }}
									></div>
								</div>
							</div>
							{/* Fähigkeiten, Wachsen, Potential, Können, Geschick, Selbstbewußtsein, Spaß,
						Gehirn, gesamt, Kompetenzen, sozial, Respect, Reglen, Struktur, Erziehung,
						Charakter, Konflikt-Management (ala "Balance" aus Freiburg) */}
						</ShowcaseTaglineDiv>
					</ShowcaseContentDiv>
				</BackgroundImage>
			</ShowcaseHeader>

			<BoxRow
				backgroundStyle={{
					backgroundImage: `url("${withPrefix("/images/galoxee-tile-blue.svg")}")`,
					color: "white",
					flexWrap: "wrap",
					padding: "2em 0 1em",
				}}
			>
				<div
					style={{
						flex: 1,
						padding: "0 .5em 1em",
						backgroundColor: "rgba(0, 0, 0, 0.1)",
					}}
				>
					<CommonArticleLayout
						{...CommonArticleLayoutProps1}
						title="Spiel 1"
						content="Hier lernst Du alle Buchstaben von A-Z in ihrer Reihenfolge kennen"
						navLinks={[
							{
								label: "PDF Herunterladen",
								url: withPrefix("/pdfs/Galoxee-ABC-Spiel-01.pdf"),
							},
						]}
						inlineImage={
							<div style={{ margin: "0 1em" }}>
								<Img
									fluid={data.spiel01.childImageSharp.fluid}
									style={{
										maxWidth: "10em",
										margin: "1em auto",
									}}
								/>
							</div>
						}
						articleOrder="HCLI"
						// minHeights={["auto", "100%", "100%"]}
						// heights={["auto", "100%", "100%"]}
						articleStyle={css`
							text-align: center;
							padding: 3em 0 1em;
							font-size: 115%;
							margin: 0 auto;
						`}
						textStyle={css`
							max-width: 30em;
							margin: 0 auto;
						`}
						textColor="white"
					/>
				</div>
				<div
					style={{
						flex: 1,
						padding: "0 .5em 1em",
						backgroundColor: "rgba(0, 0, 0, 0.1)",
					}}
				>
					<CommonArticleLayout
						{...CommonArticleLayoutProps1}
						title="Spiel 2"
						content="Bei diesem Spiel mußt Du Worte erraten und im Satz ergänzen"
						navLinks={[
							{
								label: "PDF Herunterladen",
								url: withPrefix("/pdfs/Galoxee-ABC-Spiel-02.pdf"),
							},
						]}
						inlineImage={
							<div style={{ margin: "0 1em" }}>
								<Img
									fluid={data.spiel02.childImageSharp.fluid}
									style={{
										maxWidth: "10em",
										margin: "1em auto",
									}}
								/>
							</div>
						}
						articleOrder="HCLI"
						// minHeights={["auto", "100%", "100%"]}
						// heights={["auto", "100%", "100%"]}
						articleStyle={css`
							text-align: center;
							padding: 3em 0 1em;
							font-size: 115%;
							margin: 0 auto;
						`}
						textStyle={css`
							max-width: 30em;
							margin: 0 auto;
						`}
						textColor="white"
					/>
				</div>
				<div
					style={{
						flex: 1,
						padding: "0 .5em 1em",
						backgroundColor: "rgba(0, 0, 0, 0.1)",
					}}
				>
					<CommonArticleLayout
						{...CommonArticleLayoutProps1}
						title="Spiel 3"
						content="Wenn Du längere Sätze schreiben willst, dann probiere dieses Spiel"
						navLinks={[
							{
								label: "PDF Herunterladen",
								url: withPrefix("/pdfs/Galoxee-ABC-Spiel-03.pdf"),
							},
						]}
						inlineImage={
							<div style={{ margin: "0 1em" }}>
								<Img
									fluid={data.spiel03.childImageSharp.fluid}
									style={{
										maxWidth: "10em",
										margin: "1em auto",
									}}
								/>
							</div>
						}
						articleOrder="HCLI"
						// minHeights={["auto", "100%", "100%"]}
						// heights={["auto", "100%", "100%"]}
						articleStyle={css`
							text-align: center;
							padding: 3em 0 1em;
							font-size: 115%;
							margin: 0 auto;
						`}
						textStyle={css`
							max-width: 30em;
							margin: 0 auto;
						`}
						textColor="white"
					/>
				</div>
			</BoxRow>

			<div style={{ height: ".05rem", backgroundColor: "var(--galoxeeOrange)" }} />
		</Layout>
	);
};

export default IndexPage;
