import * as _ from "lodash";
import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

const BackgroundImage = require("gatsby-background-image").default;

export interface CommonArticleLayoutProps {
	readonly articlePadding?: string;
	readonly articleStyle?: FlattenSimpleInterpolation;
	readonly title?: JSX.Element | string;
	readonly titleMarginTop?: string;
	readonly titleStyle?: React.CSSProperties;
	readonly titleTag?: string;
	readonly subtitle?: JSX.Element | string;
	readonly subtitleMarginTop?: string;
	readonly subtitleTag?: string;
	readonly subtitleStyle?: React.CSSProperties;
	readonly content?: JSX.Element | string;
	readonly contentLineSpacing?: string;
	readonly contentMarginTop?: string;
	readonly contentStyle?: React.CSSProperties;
	readonly nav?: JSX.Element | string;
	readonly navStyle?: React.CSSProperties;
	readonly navMarginTop?: string;
	readonly navLinks?: ReadonlyArray<{ readonly label: string; readonly url: string }>;
	readonly navLinkStyle?: React.CSSProperties;
	readonly textStyle?: FlattenSimpleInterpolation;
	readonly inlineImage?: JSX.Element;
	/**
	 * String with characters "HCLI", for H, content, links, image.
	 * The order that they are listed here is the order in which they will appear.
	 */
	readonly articleOrder?: string;
	readonly articleUrl?: string;

	readonly textColor?: string;
}

export interface CommonLayoutProps extends CommonArticleLayoutProps {
	readonly article?: JSX.Element;
	readonly figure?: JSX.Element;
	readonly figurePlacementMobile?: "top" | "bottom";
	readonly figurePlacementDesktop?: "top" | "bottom" | "left" | "right";
	readonly imageFluid: any;
	readonly imageSize?: "cover" | "contain";
	readonly imagePosition?: string; // e.g. top, bottom, etc.  See the `background-position` property
	readonly imageStyle?: FlattenSimpleInterpolation;

	readonly rowStyle?: FlattenSimpleInterpolation;
	readonly articleColStyle?: FlattenSimpleInterpolation;
	readonly figureColStyle?: FlattenSimpleInterpolation;
	readonly heights?: [string, string, string];
	readonly minHeights?: [string, string, string];
	// readonly figureHeights?: [string, string, string];
	readonly figureHeightMobile: string;
}

export const CommonArticleLayoutProps1: Partial<CommonArticleLayoutProps> = {
	articleOrder: "HLC",
	titleTag: "h2",
	subtitleMarginTop: "0.7em",
	subtitleTag: "h3",
	navMarginTop: "0.7em",
	contentLineSpacing: "1.7em",
	contentMarginTop: "0.7em",
	articleStyle: css`
		text-align: center;
		padding: 3em 1em;
	`,
};

export const CommonLayoutProps1: Partial<CommonLayoutProps> = {
	articleOrder: "HLC",
	titleTag: "h2",
	subtitleMarginTop: "0.7em",
	subtitleTag: "h3",
	navMarginTop: "0.7em",
	contentLineSpacing: "1.7em",
	contentMarginTop: "0.7em",
	articleStyle: css`
		text-align: center;
		padding: 3em 1em;
	`,
};

export const VSpace = styled.div<{ readonly height: string }>`
	height: ${props => props.height};
`;

export function CommonArticleLayout(props: CommonArticleLayoutProps): JSX.Element {
	const titleTag = props.titleTag || "h2";
	const Title = props.title
		? React.createElement(titleTag, { style: props.titleStyle }, props.title)
		: undefined;

	const subtitleTag = props.subtitleTag || "h3";
	const Subtitle = props.subtitle
		? React.createElement(subtitleTag, { style: props.subtitleStyle }, props.subtitle)
		: undefined;

	const Header =
		Title || Subtitle ? (
			<header key="H">
				{Title}
				{Subtitle}
			</header>
		) : (
			undefined
		);

	const Wrapper = styled.article`
		color: ${props.textColor || "inherit"};

		& h1,
		h2,
		h3,
		h4,
		nav,
		p {
			margin: 0 0 0 0;
			padding: 0;
		}

		& header {
			padding-top: ${props.titleMarginTop || "0"};
		}

		nav {
			padding: ${props.navMarginTop || "0"} 0;
		}

		a {
			color: ${props.textColor || "inherit"};
			padding: 0 1em;
		}

		p {
			padding-top: ${props.contentMarginTop || "0"};
			line-height: ${props.contentLineSpacing || "1em"};
		}

		& header,
		nav,
		p {
			${props.textStyle || ""}
		}

		${props.articleStyle};
	`;

	const Links = props.nav ? (
		props.nav
	) : props.navLinks ? (
		<nav key="L" style={props.navStyle}>
			{(props.navLinks || []).map((x, i) => (
				<a key={"link" + (i + 1)} href={x.url} style={props.navLinkStyle}>
					{x.label} &rsaquo;
				</a>
			))}
		</nav>
	) : null;

	const Contents =
		typeof props.content === "string" ? (
			<p key="C" style={props.contentStyle}>
				{props.content}
			</p>
		) : (
			<React.Fragment key="C">{props.content}</React.Fragment>
		);

	const children = _.flatMap((props.articleOrder || "HCLI") as any, c => {
		if (c === "H") {
			return [Header];
		} else if (c === "C" && Contents) {
			return [Contents];
		} else if (c === "L" && Links) {
			return [Links];
		} else if (c === "I" && props.inlineImage) {
			return [<React.Fragment key="I">{props.inlineImage}</React.Fragment>];
		}
		return [];
	});

	return <Wrapper>{children}</Wrapper>;
}

interface RowProps {
	readonly flexDirection: string;
	readonly rowStyle?: FlattenSimpleInterpolation;
	readonly heights?: [string, string, string];
	readonly minHeights?: [string, string, string];
}

const Row = styled.div<RowProps>`
	width: 100%;
	${props => (props.heights ? `height: ${props.heights[0]};` : "")}
	${props =>
		props.minHeights ? `min-height: ${props.minHeights[0]};` : ""}
	@media (min-width: 742px) {
		${props => (props.heights ? `height: ${props.heights[1]};` : "")}
		${props => (props.minHeights ? `min-height: ${props.minHeights[1]};` : "")}
		display: flex;
		flex-direction: ${props => props.flexDirection};
	}
	@media (min-width: 920px) {
		${props => (props.heights ? `height: ${props.heights[2]};` : "")}
		${props => (props.minHeights ? `min-height: ${props.minHeights[2]};` : "")}
	}

	${props => props.rowStyle || ""}
`;

interface Col50Props {
	readonly order: number;
	readonly width: string;
	readonly colStyle?: FlattenSimpleInterpolation;
}

const Col50 = styled.div<Col50Props>`
	order: ${props => props.order};
	width: 100%;
	@media (min-width: 742px) {
		width: ${props => props.width};
		flex: 1;
		display: flex;
	}
	${props => props.colStyle}
`;

interface CommonBackgroundImageProps {
	readonly imageHeightMobile: string;
	readonly imagePosition?: string;
	readonly imageSize?: string;
	readonly heights?: [string, string, string];
	readonly minHeights?: [string, string, string];
	readonly imageStyle?: FlattenSimpleInterpolation;
}

const CommonBackgroundImage = styled(BackgroundImage)<CommonBackgroundImageProps>`
	background-position: ${props => props.imagePosition || "center"};
	background-size: ${props => props.imageSize || "cover"};
	padding-top: ${props => props.imageHeightMobile};
	flex: 1;
	z-index: 0;
	${props => (props.heights ? `height: ${props.heights[0]};` : "")}
	${props =>
		props.minHeights ? `min-height: ${props.minHeights[0]};` : ""}
	@media (min-width: 742px) {
		${props => (props.heights ? `height: ${props.heights[1]};` : "")}
		${props => (props.minHeights ? `min-height: ${props.minHeights[1]};` : "")}
		display: flex;
		padding: 0;
	}
	@media (min-width: 920px) {
		${props => (props.heights ? `height: ${props.heights[2]};` : "")}
		${props => (props.minHeights ? `min-height: ${props.minHeights[2]};` : "")}
	}
	&:before,
	&:after {
		background-position: ${props => props.imagePosition};
		background-size: ${props => props.imageSize};
	}
	${props => props.imageStyle}
`;

/**
 *
 * ```
 * <row>
 *  <col>
 *   <article/>
 *  </col>
 *  <col>
 *   <figure/>
 *  </col>
 * </row>
 * ```
 *
 * The ROW is a flexbox container, as is the COL for ARTICLE (not not for FIGURE).
 */
export function CommonLayout(props: CommonLayoutProps): JSX.Element {
	const figureFirstMobile = props.figurePlacementMobile === "top" ? true : false;
	const figureFirstDesktop =
		props.figurePlacementDesktop === "top" || props.figurePlacementDesktop === "left"
			? true
			: false;
	const flexDirection =
		props.figurePlacementDesktop === "top" || props.figurePlacementDesktop === "bottom"
			? "column"
			: "row";
	const colWidth = flexDirection === "row" ? "50%" : "100%";

	const figureCol = (
		<Col50 order={figureFirstDesktop ? 1 : 2} width={colWidth} colStyle={props.figureColStyle}>
			<CommonBackgroundImage
				imageHeightMobile={props.figureHeightMobile}
				heights={props.heights}
				minHeights={props.minHeights}
				imageSize={props.imageSize}
				imagePosition={props.imagePosition}
				fluid={props.imageFluid}
				imageStyle={props.imageStyle}
			/>
			{/* <Img fluid={props.fluidImage} /> */}
		</Col50>
	);

	const ContentCol = styled(Col50)`
		@media (min-width: 742px) {
			flex: 1;
			display: flex;
		}
	`;
	// const contentCol = <ContentCol {...props} />;
	const contentCol = (
		<ContentCol
			order={figureFirstDesktop ? 2 : 1}
			width={colWidth}
			colStyle={props.articleColStyle}
		>
			<CommonArticleLayout
				{...props}
				articleStyle={css`
					flex: 1;
					${props.articleStyle}
				`}
			/>
		</ContentCol>
	);

	return (
		<Row
			flexDirection={flexDirection}
			rowStyle={props.rowStyle}
			heights={props.heights}
			minHeights={props.minHeights}
		>
			{figureFirstMobile ? (
				<>
					{figureCol}
					{contentCol}
				</>
			) : (
				<>
					{contentCol}
					{figureCol}
				</>
			)}
		</Row>
	);
}
